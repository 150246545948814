import React from 'react';


var bgimg2 = require('./../../../images/background/14803753_Vector_2677-1-scaled.jpg');


class WhatWeDo1 extends React.Component {
    render() {
        return (
            <>
<div className="section-full  mobile-page-padding bg-white1  ">
    {/*<span class="absolute bottom-0 right-0 block w-64 h-64 mb-32 mr-4 transition duration-500 origin-bottom-left transform rotate-45 translate-x-24 bg-pink-500 rounded-full opacity-30 group-hover:rotate-90 ease"></span>*/}
    <div className="right-half-bg-image-outer">
        <div className="bg-parallax bg-fixed bg-top-right" data-stellar-background-ratio={0} style={{ backgroundImage: 'url(' + bgimg2 + ')' }} />

        <div className="section-content">
            <div className="row number-block-one-outer justify-content-center popopoppo ">

                <div className="col-lg-5 col-md-12 col-sm-12 p-t80 p-b50 bg-repeat overflow-hide wer">
                <div className="blur-bg-blocks">
            <aside className="blur-bg-set">
               <div className="blur-bg blur-bg-a"></div>
               <div className="blur-bg blur-bg-b"></div>
               <div className="blur-bg blur-bg-c"></div>
            </aside>
         </div>
                         <div className="number-block-one  animate-in-to-top fgghhjkhjjg">
                        <img src={require('./../../../images/background/Asset.png')} alt="" />
                    </div>
                </div>
                <div className="col-lg-7 col-md-12 col-sm-12 p-t80 p-b50 bg-repeat overflow-hide back-ground-color cvcvvcvvc">
                    {/* TITLE START */}
                    <div className="section-head">
                        <div className="sx-separator-outer separator-left">
                            <div className="ertyui">
                                <h3 className="">Why Choose Us</h3>
                                <p>
                                    An enterprise-level website comes with thousands of web pages. Dealing with that kind of scale requires Enterprise SEO experts’ intervention. We tackle complexity, scale, and volume with ease to give you
                                    the results you need. Therefore, upgrading to enterprise SEO services has many benefits:
                                </p>
                            </div>
                            
                        </div>

                    </div>
                    <ul className="alal23">
                                        <li>User Friendly</li>
                                        <li>User Friendly</li>
                                        <li>Responsive</li>
                                        
                                    </ul>
                                    <ul className="alal23">
                                        <li>User Friendly</li>
                                        <li>User Friendly</li>
                                        <li>Responsive</li>
                                        
                                    </ul>
                                    <ul className="alal23">
                                        <li>User Friendly</li>
                                        <li>User Friendly</li>
                                        <li>Responsive</li>
                                        
                                    </ul>
                    {/* TITLE END */}
                </div>
            </div>
        </div>
    </div>
</div>
</>
        );
    }
};

export default WhatWeDo1;