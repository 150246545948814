import React from "react";
import Header2 from "./../Common/Header3";
import Footer from "./../Common/Footer";
import Footer2 from "./../Common/Footer2";
import SMOBanner from "./../Elements/SMO/SMOBanner";
import SMOAbout from "./../Elements/SMO/SMOAbout";
import SMOClient from "./../Elements/SMO/SMOClient";
import WebWebSiteProcess from "./../Elements/SMO/WebWebSiteProcess";
import SMOWhyChooseUs from "./../Elements/SMO/SMOWhyChooseUs";
import SMOService from "./../Elements/SMO/SMOService";
import SMOServices from "./../Elements/SMO/SMOServices";
import SMMOnline from "./../Elements/SMO/SMMOnline";
import ClientsLogo2 from "./../Elements/ClientsLogo2";
import SMOFAQ from "./../Elements/SMO/SMOFAQ";
import WebFAQ from "./../Elements/SMO/WebFAQ";
import CustomPlan from "./../Elements/CustomPlan";
import SocialMediaIcon from "./../Elements/SocialMediaIcon";
import CommenEnquiry from "./../Elements/CommenEnquiry";
import Testimonials from "./../Elements/Testimonials";
import { Helmet } from "react-helmet";

var bnrimg = require("./../../images/banner/web-development-banner.jpg");

class Service1 extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <title>
            Boost Traffic Organically with the Best SEO Services Company
          </title>
          <meta
            name="description"
            content="Shadow infosystem, Best SEO services company, dedicated to boosting organic traffic & visibility. Elevate your online presence and drive success with us"
          />
          <meta name="keywords" content="SEO Services Provider in Delhi" />
          <link
            rel="canonical"
            href="https://www.shadowinfosystem.com/search-engine-optimization"
          />
        </Helmet>
        <Header2 />
        <div className="page-content">
          <SMOBanner />
          <SMOAbout />
          <SMOServices />
          <WebWebSiteProcess />
          {/*<CommenEnquiry />*/}
          <SMMOnline />
          <SMOWhyChooseUs />
          <WebFAQ />
          <SMOClient />
          <Testimonials />
          <SMOFAQ />
        </div>

        <Footer2 />
      </>
    );
  }
}

export default Service1;
