import React from "react";
import { NavLink } from "react-router-dom";

class Slider3 extends React.Component {
  componentDidMount() {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement("script");
        script.src = src;
        script.addEventListener("load", function () {
          resolve();
        });
        script.addEventListener("error", function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }

    loadScript("./assets/js/rev-script-3.js");
  }
  render() {
    return (
      <>
        <div
          id="rev_slider_three_wrapper"
          className="rev_slider_wrapper fullscreen-container"
          data-alias="mask-showcase"
          data-source="gallery"
          style={{ background: "#aaaaaa", padding: 0 }}
        >
          {/* START REVOLUTION SLIDER 5.4.1 fullscreen mode */}
          <div
            id="rev_slider_three"
            className="rev_slider fullscreenbanner"
            style={{ display: "none" }}
            data-version="5.4.1"
          >
            <ul className="">
              {/* SLIDE 2 */}
              <li>
                {/* MAIN IMAGE */}
                <img
                  src={require("./../../../images/main-slider/slider5/new-slider-bg.webp")}
                  alt="shadow_banner"
                  data-bgcolor="#f8f8f8"
                />
                {/* LAYER 1  right image overlay dark*/}

                <div className="ertyuioiu-1"></div>
                <div className="container">
                  <div className="row">
                    {/* Head Office */}

                    <div className="col-md-8">
                      <div className="section-one rrtyui">
                        <div className="banner-content rtyuiopoiu">
                          <h2>
                            Accelerate Your Business & Build it in a Brand
                          </h2>
                          <p>
                            Get the full potential of your business in the
                            digital landscape with our cutting-edge technologies
                            and expert strategies. Let us be your trusted
                            partner in driving growth and achieving lasting
                            success.
                          </p>

                          <div className="dndu108">
                            <img
                              src={require("./../../../images/background/Google-1-1.png")}
                              className="iuytre"
                              alt="shadow_partner"
                            />

                            <img
                              src={require("./../../../images/background/Amazon-1-1.png")}
                              className="iuytre"
                              alt="shadow_partner"
                            />

                            <img
                              src={require("./../../../images/background/38ccd21e-2bce-4422-828d-3eeeb171027c.png")}
                              className="iuytre"
                              alt="shadow_partner"
                            />
                            <img
                              src={require("./../../../images/Layer.png")}
                              className="iuytre1"
                              alt="shadow_partner"
                            />
                          </div>
                          <div className="buttonfgerb mt-3">
                            <div className="header-section-12">
                              <NavLink
                                to={"/search-engine-optimization"}
                                title="READ MORE"
                                rel="bookmark"
                                className="hs12-button"
                              >
                                Find out more
                              </NavLink>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4"></div>
                  </div>
                </div>
                {/* LAYER 3  Thin text title*/}
              </li>
            </ul>
            <div
              className="tp-bannertimer"
              style={{ height: 10, background: "rgba(0, 0, 0, 0.15)" }}
            />
          </div>
        </div>
      </>
    );
  }
}

export default Slider3;
