import React from "react";

const achievements1 = [
  {
    title: "Search Advertising",
    image: require("./../../../images/icon/google-web.png"),
  },
  {
    title: "Display Advertising",
    image: require("./../../../images/icon/google-web.png"),
  },
  {
    title: "Social Media Advertising",
    image: require("./../../../images/icon/google-web.png"),
  },
];

const achievements2 = [
  {
    title: "Remarketing",
    image: require("./../../../images/icon/google-web.png"),
  },
  {
    title: "Google Shopping Ads",
    image: require("./../../../images/icon/google-web.png"),
  },
  {
    title: "Explore PPC Packages",
    image: require("./../../../images/icon/google-web.png"),
  },
];

var bgimg1 = require("./../../../images/background/bg-map.png");
var bgimg2 = require("./../../../images/background/cross-line2.png");

class Achievements1 extends React.Component {
  render() {
    return (
      <>
        <div className="section-full p-t80 p-b50 bg-no-repeat bg-bottom-center mobile-page-padding">
          <div className="container">
            {/* TITLE START */}
            <div className="section-head">
              <div className="sx-separator-outer separator-center">
                <div className="bg-moving bg-repeat-x">
                  <h2 className="ert678 heading">
                    Our PPC Advertising Services
                  </h2>
                  <div className="sub-heading">
                    Get More Traffic, Generate More Leads and Sales with Our PPC
                    Services
                  </div>
                </div>
              </div>
            </div>
            {/* TITLE END */}
            <div className="section-content ">
              <div className="row">
                {/*<div className="col-xl-4 col-lg-6 col-md-12 m-b30">
                                    <div className="number-block-one  animate-in-to-top fgghhjkhjjg">
                                        <img src={require('./../../../images/icon/graphic-designer.png')} alt="" />
                                        
                                    </div>
                                </div>*/}
                <div className="col-xl-6 col-lg-6 col-md-12 m-b30">
                  {achievements1.map((item, index) => (
                    <div className="sx-box our-story cvcvcv" key={index}>
                      <div className="tret">
                        <img src={item.image} alt="" />
                        <h4>{item.title}</h4>
                        <p>{item.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12">
                  {achievements2.map((item, index) => (
                    <div className="sx-box our-story cvcvcv" key={index}>
                      <div className="tret">
                        <img src={item.image} alt="" />
                        <h4>{item.title}</h4>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Achievements1;
