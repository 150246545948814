import React from 'react';
import Header2 from './../Common/Header3';
import Footer from './../Common/Footer';
import Footer2 from './../Common/Footer2';
import About from './../Elements/ThankYou/About';
import Banner from './../Elements/ThankYou/Banner';
import CulAbout from './../Elements/ThankYou/CulAbout';



var bnrimg = require('./../../images/banner/web-development-banner.jpg');

class Service1 extends React.Component {
    render() {
        return (
            <>
                <Header2 />
                <div className="page-content">
                
                    <About/>
                     
                   

                    
                </div>
               
                 <Footer2 />
            </>
        );
    };
};

export default Service1;