import React from 'react';
import { NavLink } from 'react-router-dom';
import ReactPlayer from 'react-player';

var bgimg1 = require('./../../../images/testimonials/Sana-Singh-Review-1-1-140x140@2x.jpg');

class About3 extends React.Component {
    render() {
        return (
            <>
                <div className={`${this.props.bgcolor} section-full mobile-page-padding p-t80 back-ground-color`}>
                    <div className="container-fluid">
                        <div className="section-content">
                         <div className="container">
                             
                        <div className="qpqq">
                          
                            <div className="row">
                            
                                <div className="col-xl-12 col-lg-12 col-md-12">
                                    <div className="section-head">
                                <div className="sx-separator-outer">
                                    <div className="">
                                        <h1 className="ert678">Success Hub: Customer Stories, Successful Case Study</h1>
                                        <h6>There are so many things to think about, like whether the agency can actually deliver on their promises or if they’ll drain your cash every month with no return.</h6>
                                        
                                    </div>
                                    
                                </div>
                            </div>
                                </div>
                                
                                
                                </div>
                                
                        </div>
                       
                         </div>
                    </div>
                </div>
                </div>
                
            </>
        );
    }
};

export default About3;