import React from 'react';
import CountUp from 'react-countup';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { NavLink } from 'react-router-dom';
const testimonials = [
    {
        image: require('./../../../images/testimonials/ravi-img1-140x140@2x.png'),
        reviewername: 'Maj Gen Ravi Arora (Retd)',
        position: 'Gen',
        review: 'Shadow infosystem has a dynamic and competent team. Their range of work is diverse. Delivery is timely. Technical support is effective. In short they live up to their promise.'
    },
    {
        image: require('./../../../images/testimonials/manash-img-140x140@2x.png'),
        reviewername: 'Manas Dasgupta',
        position: 'Gen',
        review: 'From BCIC, we are very happy with the flexibility that you have provided to us and openness to feedbacks always.'
    },
]

var bgimg1 = require('./../../../images/background/pattern-1.png');
var bgimg2 = require('./../../../images/background/shape-bg.png');


class Testimonials1 extends React.Component {
    render() {
        const options = {
            loop: true,
            autoplay: true,
            margin: 30,
            autoplayTimeout: 6000,
            nav: true,
            dots: true,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            responsive: {
                0: {
                    items: 1
                },
                991: {
                    items: 1
                }
            }
        };
        return (
            <>
                <div className="section-full container-fluid dffddff554654">
                
                    <div className="section-content">
                        <div className="row">
                            <div className="col-xl-12 col-lg-6 col-md-12 bg-cover bg-no-repeat ">
                                <div className="sx-left-part mobile-page-padding">
                                   
                                    <div className="counter-blocks">
                                       
                                                <div className="sx-count  text-black sx-icon-box-wraper bg-repeat number-back">
                                                    <h3 className="st-count-number ert678 text-left">
                                                    <span className="counter"><CountUp end={14} duration={5} />+</span></h3>
                                                    <p className="m-tb0">Years Of Experience</p>
                                                </div>
                                           

                                            
                                                <div className="sx-count text-black sx-icon-box-wraper bg-repeat number-back">
                                                    <h3 className="st-count-number ert678 text-left">
                                                    <span className="counter"><CountUp end={3000} duration={5} />+</span></h3>
                                                    <p className="m-tb0">live on Internet</p>
                                                </div>
                                            
                                            
                                                <div className="sx-count text-black sx-icon-box-wraper bg-repeat number-back">
                                                    <h3 className="st-count-number ert678 text-left">
                                                    <span className="counter"><CountUp end={4000} duration={5} />+</span></h3>
                                                    <p className="m-tb0">Developed websites</p>
                                                </div>
                                           
                                            
                                                <div className="sx-count text-black sx-icon-box-wraper bg-repeat number-back">
                                                    <h3 className="st-count-number ert678 text-left">
                                                    <span className="counter"><CountUp end={16} duration={5} />+</span></h3>
                                                    <p className="m-tb0">Excellence Awards</p>
                                                </div>
                                           
                                           
                                            

                                     
                                    </div>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default Testimonials1;