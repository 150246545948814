import React from "react";
import Header2 from "./../Common/Header3";
import Footer2 from "./../Common/Footer2";
import PartnerBanner from "./../Elements/Partner/PartnerBanner";
import PartnerAbout from "./../Elements/Partner/PartnerAbout";
import PartnerService from "./../Elements/Partner/PartnerService";
import { Helmet } from "react-helmet";
import PartnerStep from "./../Elements/Partner/PartnerStep";
import PartnerWhyus from "./../Elements/Partner/PartnerWhyus";
import PartnerWithus from "./../Elements/Partner/PartnerWithus";
import PartnerEnquiry from "./../Elements/Partner/PartnerEnquiry";
import PartnerOnline from "./../Elements/Partner/PartnerOnline";

var bnrimg = require("./../../images/banner/shadow-banner-19.jpg");

class Service1 extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <title>
            Boost Traffic and Visibility Organically | Best SEO Agency
          </title>
          <meta name="description" content="#" />
          <meta name="keywords" content="#" />
          <link rel="canonical" href="#" />
        </Helmet>
        <Header2 />
        <div className="page-content">
          <PartnerBanner />
          <PartnerAbout />
          <PartnerService />
          <PartnerOnline />
          <PartnerWithus />
          <PartnerEnquiry />
          <PartnerStep />
          <PartnerWhyus />
        </div>

        <Footer2 />
      </>
    );
  }
}

export default Service1;
