import React from 'react';
import { NavLink } from 'react-router-dom';

const services = [
    {
       
        title: 'Global SEO',
        image: require('./../../../images/icon/web-link.png'),
        description: 'Let the SEO Experts rank your website on top of search engine sites like Google, Yahoo, and Bing!',
    },
    {
        
        title: 'National SEO',
        image: require('./../../../images/icon/web-link.png'),
        description: 'Let the SEO Experts rank your website on top of search engine sites like Google, Yahoo, and Bing!',
    },
    {
        
        title: 'Local SEO',
        image: require('./../../../images/icon/web-link.png'),
        description: 'Let the SEO Experts rank your website on top of search engine sites like Google, Yahoo, and Bing!',
    },
    {
        
        title: 'Enterprise SEO',
        image: require('./../../../images/icon/web-link.png'),
        description: 'Let the SEO Experts rank your website on top of search engine sites like Google, Yahoo, and Bing!',
    },
    {
        
        title: 'E-Commerce SEO',
        image: require('./../../../images/icon/web-link.png'),
        description: 'Let the SEO Experts rank your website on top of search engine sites like Google, Yahoo, and Bing!',
    },
    {
       
        title: 'Start-Up SEO',
        image: require('./../../../images/icon/web-link.png'),
        description: 'Let the SEO Experts rank your website on top of search engine sites like Google, Yahoo, and Bing!',
    }
]

var bgimg1 = require('./../../../images/background/cross-line2.png');

class Services1 extends React.Component {
    render() {

        return (
            <>
                <div className="section-full mobile-page-padding p-t80  p-b50 bg-gray">
                
                    <div className="section-content">
                        <div className="container">
                            {/* TITLE START */}
                            
                            {/* TITLE END */}
                            <div className="row">
                                {services.map((item, index) => (
                                    <div className="col-lg-4 col-md-6 col-sm-12 m-b30" key={index}>
                                        <div className="sx-icon-box-wraper  icon-count-2-outer">
                                            <div className="icon-count-2 bg-white seo-service-type">
                                                
                                                <div className="icon-xl inline-icon m-b5 scale-in-center">
                                                    <span className="icon-cell"><img src={item.image} alt="" /></span>
                                                </div>
                                                <div className="icon-content">
                                                    <h4 className="sx-tilte">{item.title}</h4>
                                                    <p>{item.description}</p>
                                                    <div class="client-logo-pic1">
                                                               <div className="text-left">
                                                               <NavLink to="#" className="site-button btn-half"><span>Read more</span></NavLink>
                                                               </div>
                                                               </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="hilite-title text-left p-l50 text-uppercase">
                        <strong>Services</strong>
                    </div>
                </div>
            </>
        );
    }
};

export default Services1;