import React from "react";

function PartnerWithus() {
  return (
    <>
      <div className="section-full mobile-page-padding p-t80 p-b50 ertyu45 hg-123">
        <div className="container">
          {/* TITLE START */}
          <div className="section-head">
            <div className="sx-separator-outer text-center">
              <div className="color-change bg-moving bg-repeat-x">
                <h2 className="wfgrbwe ert678 heading mb-3">
                  Who Can Be Our Partner?
                </h2>
              </div>
            </div>
          </div>
          {/* TITLE END */} {/* IMAGE CAROUSEL START */}
          <div className="section-content">
            <div class="row">
              <div class="col-md-3">
                <div class="card mb-3">
                  <div class="card-content">
                    <div class="card-body">
                      <div class="media d-block">
                        <div class="media-body text-center">
                          <div class="sub-heading-partner">PR Agency</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="card mb-3">
                  <div class="card-content">
                    <div class="card-body">
                      <div class="media d-block">
                        <div class="media-body text-center">
                          <div class="sub-heading-partner">Influencers </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="card mb-3">
                  <div class="card-content">
                    <div class="card-body">
                      <div class="media d-block">
                        <div class="media-body text-center">
                          <div class="sub-heading-partner">IT Companies</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="card mb-3">
                  <div class="card-content">
                    <div class="card-body">
                      <div class="media d-block">
                        <div class="media-body text-center">
                          <div class="sub-heading-partner">
                            Business Advisers
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="card mb-3">
                  <div class="card-content">
                    <div class="card-body">
                      <div class="media d-block">
                        <div class="media-body text-center">
                          <div class="sub-heading-partner">Event Agencies</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="card mb-3">
                  <div class="card-content">
                    <div class="card-body">
                      <div class="media d-block">
                        <div class="media-body text-center">
                          <div class="sub-heading-partner">
                            Print Media Ad Agency
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="card mb-3">
                  <div class="card-content">
                    <div class="card-body">
                      <div class="media d-block">
                        <div class="media-body text-center">
                          <div class="sub-heading-partner">Freelancers</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="card mb-3">
                  <div class="card-content">
                    <div class="card-body">
                      <div class="media d-block">
                        <div class="media-body text-center">
                          <div class="sub-heading-partner">
                            Branding Agencies
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="card mb-3">
                  <div class="card-content">
                    <div class="card-body">
                      <div class="media d-block">
                        <div class="media-body text-center">
                          <div class="sub-heading-partner">
                            Creative Agencies
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="card mb-3">
                  <div class="card-content">
                    <div class="card-body">
                      <div class="media d-block">
                        <div class="media-body text-center">
                          <div class="sub-heading-partner">
                            Film Production Houses
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="card mb-3">
                  <div class="card-content">
                    <div class="card-body">
                      <div class="media d-block">
                        <div class="media-body text-center">
                          <div class="sub-heading-partner">
                            Digital Independents
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="card mb-3">
                  <div class="card-content">
                    <div class="card-body">
                      <div class="media d-block">
                        <div class="media-body text-center">
                          <div class="sub-heading-partner">Consultants</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PartnerWithus;
