import React from "react";
import Header2 from "./../Common/Header3";
import Footer2 from "./../Common/Footer2";
import TSBanner from "./../Elements/TermsOfServices/TSBanner";
import TSContent from "./../Elements/TermsOfServices/TSContent";

var bnrimg = require("./../../images/banner/shadow-banner-19.jpg");

class Service1 extends React.Component {
  render() {
    return (
      <>
        {/* <Helmet>
          <title>
            Boost Traffic and Visibility Organically | Best SEO Agency
          </title>
          <meta
            name="description"
            content="Shadow infosystem, best SEO Agency, dedicated to boosting organic traffic & visibility effortlessly. Elevate your online presence and drive success with us"
          />
          <meta
            name="keywords"
            content="SEO services provider company, Best SEO Services Provider, Best SEO Services Company, Best SEO Services Agency"
          />
          <link
            rel="canonical"
            href="https://www.shadowinfosystem.com/search-engine-optimization"
          />
        </Helmet> */}
        <Header2 />
        <div className="page-content">
          <TSBanner />
          <TSContent />
        </div>

        <Footer2 />
      </>
    );
  }
}

export default Service1;
