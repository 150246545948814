import React from "react";
import Header2 from "./../Common/Header3";
import Footer from "./../Common/Footer";
import Footer2 from "./../Common/Footer2";
import Banner from "./../Elements/Banner";
import PPCAbout from "./../Elements/PPC/PPCAbout";
import PPCService from "./../Elements/PPC/PPCService";
import PPCWhyChooseUs from "./../Elements/PPC/PPCWhyChooseUs";
import Services7 from "./../Elements/Services7";
import PPCBanner from "./../Elements/PPC/PPCBanner";
import PPCAd from "./../Elements/PPC/PPCAd";

import PPCFAQ from "./../Elements/PPC/PPCFAQ";
import PPCCall from "./../Elements/PPC/PPCCall";
import PPCSolution from "./../Elements/PPC/PPCSolution";
import ElitePPC from "./../Elements/PPC/ElitePPC";
import { Helmet } from "react-helmet";
import Testimonials from "./../Elements/Testimonials";

var bnrimg = require("./../../images/banner/web-development-banner.jpg");

class Service1 extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <title>Best Pay Per Click (PPC) Company | Shadow infosystem</title>
          <meta
            name="description"
            content="Shadow infosystem, the best PPC Company. Boost your online presence and ROI with our expert PPC strategies. To get results-driven campaigns, contact us!"
          />
          <meta
            name="keywords"
            content="Cyber security services provider company"
          />
          <link
            rel="canonical"
            href="https://www.shadowinfosystem.com/pay-per-click"
          />
        </Helmet>
        <Header2 />
        <div className="page-content">
          <PPCBanner />
          <PPCAbout />
          <PPCCall />
          <PPCAd />
          <PPCSolution />
          <ElitePPC />
          <Testimonials />
          <PPCFAQ />
        </div>
        <Footer2 />
      </>
    );
  }
}

export default Service1;
