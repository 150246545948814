import React from 'react';
import Header2 from './../Common/Header2';
import Footer from './../Common/Footer';
import Footer2 from './../Common/Footer2';
import Banner from './../Elements/Banner';
import WebSiteTech from './../Elements/WebSiteTech';
import WebSiteProcess from './../Elements/WebSiteProcess';
import TypeDesign from './../Elements/TypeDesign';
import Services7 from './../Elements/Services7';
import ClientsLogo2 from './../Elements/ClientsLogo2';
import FAQ from './../Elements/FAQ';
import CustomPlan from './../Elements/CustomPlan';

var bnrimg = require('./../../images/banner/web-development-banner.jpg');

class Service1 extends React.Component {
    render() {
        return (
            <>
                <Header2 />
                <div className="page-content">
                    <Banner title="We offer a wide variety of website design & Development services" 
                    pagename="Web Design"
                    description=" path-breaking platform for business growth-oriented solutions." bgimage={bnrimg}/>
                    <WebSiteTech />
                    <TypeDesign />
                    <WebSiteProcess />
                    <CustomPlan />
                    <FAQ />
                </div>
                <Footer />
                 <Footer2 />
            </>
        );
    };
};

export default Service1;