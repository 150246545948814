import React from "react";
import Header2 from "./../Common/Header3";
import Footer from "./../Common/Footer";
import Footer2 from "./../Common/Footer2";
import WebsiteDevelopmentCompanyIndia from "./../Elements/WebsiteDevelopmentCompanyIndia";
import { Helmet } from "react-helmet";

var bnrimg = require("../../images/banner/shadow-banner-19.jpg");

class Service1 extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <title>Best Website Development Company in India</title>
          <meta name="description" content="Discover the best website development company in India, offering expert solutions for businesses. From design to deployment, unlock your online potential today!" />
          <meta name="keywords" content="Website Development Company in India" />
          <link rel="canonical" href=" https://www.shadowinfosystem.com/website-development-company-india" />
        </Helmet>
        <Header2 />
        <div className="page-content">
          <WebsiteDevelopmentCompanyIndia />
        </div>

        <Footer2 />
      </>
    );
  }
}
export default Service1;
