import React from 'react';
import Header2 from './../Common/Header2';
import Footer from './../Common/Footer';
import Footer2 from './../Common/Footer2';
import Banner from './../Elements/Banner';
import PHPAbout from './../Elements/PHP/PHPAbout';
import SMOClient from './../Elements/SMO/SMOClient';
import PHPWhatWeDo from './../Elements/PHP/PHPWhatWeDo';
import SMOWhyChooseUs from './../Elements/SMO/SMOWhyChooseUs';
import PHPService from './../Elements/PHP/PHPService';
import SMOServices from './../Elements/SMO/SMOServices';
import ClientsLogo2 from './../Elements/ClientsLogo2';
import SMOFAQ from './../Elements/SMO/SMOFAQ';
import CustomPlan from './../Elements/CustomPlan';
import SocialMediaIcon from './../Elements/SocialMediaIcon';

var bnrimg = require('./../../images/banner/web-development-banner.jpg');

class Service1 extends React.Component {
    render() {
        return (
            <>
                <Header2 />
                <div className="page-content">
                    <Banner title="PHP Development" 
                    pagename="PHP Development"
                    description="We are a dynamic team of creatives people of innovation & Marketing Expert" bgimage={bnrimg}/>
                    <PHPService />
                    <PHPWhatWeDo/>
                    <PHPAbout />
                    <SMOFAQ />
                </div>
                <Footer />
                <Footer2/>
            </>
        );
    };
};

export default Service1;