import React from 'react';
import Header2 from './../Common/Header2';
import Footer from './../Common/Footer';
import Footer2 from './../Common/Footer2';
import Banner from './../Elements/Banner';
import PHPAbout from './../Elements/WordpressDevelopment/WordpressDevelopmentAbout';
import WordpressBanner from './../Elements/WordpressDevelopment/WordpressBanner';
import PHPWhatWeDo from './../Elements/WordpressDevelopment/WordpressDevelopmentWhatWeDo';
import WordpressDevelopmentWhyChooseUs from './../Elements/WordpressDevelopment/WordpressDevelopmentWhyChooseUs';
import PHPService from './../Elements/WordpressDevelopment/WordpressDevelopmentService';
import SMOServices from './../Elements/WordpressDevelopment/WordpressDevelopmentServices';
import Testimonials from './../Elements/Testimonials';
import CommenEnquiry from './../Elements/CommenEnquiry';

import WordpressDevelopmentBrand from './../Elements/WordpressDevelopment/WordpressDevelopmentBrand';

var bnrimg = require('./../../images/banner/web-development-banner.jpg');

class Service1 extends React.Component {
    render() {
        return (
            <>
                <Header2 />
                <div className="page-content">
                    <WordpressBanner/>
                    <PHPAbout />
                    <PHPService />
                    <WordpressDevelopmentWhyChooseUs />
                    <WordpressDevelopmentBrand/>
                    <CommenEnquiry/>
                    <Testimonials/>
                </div>
               
                <Footer2/>
            </>
        );
    };
};

export default Service1;