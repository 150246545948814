import React from "react";
import Header2 from "./../Common/Header3";
import Footer from "./../Common/Footer";
import Footer2 from "./../Common/Footer2";
import SEOBanner from "./../Elements/SEO/SEOBanner";
import SEOAbout from "./../Elements/SEO/SEOAbout";
import SEOWhyChooseUs from "./../Elements/SEO/SEOWhyChooseUs";
import SEOServices from "./../Elements/SEO/SEOServices";
import SEOService from "./../Elements/SEO/SEOService";
import SEOFAQ from "./../Elements/SEO/SEOFAQ";
import SEOBrand from "./../Elements/SEO/SEOBrand";
import ClientsLogo2 from "./../Elements/ClientsLogo2";
import FAQ from "./../Elements/FAQ";
import SEOEnquiry from "./../Elements/SEO/SEOEnquiry";
import SEOAssist from "./../Elements/SEO/SEOAssist";
import SEOWhyus from "./../Elements/SEO/SEOWhyus";
import SEOOnline from "./../Elements/SEO/SEOOnline";
import Testimonials from "./../Elements/Testimonials";
import { Helmet } from "react-helmet";

var bnrimg = require("./../../images/banner/shadow-banner-19.jpg");

class Service1 extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <title>
            Boost Traffic and Visibility Organically | Best SEO Agency
          </title>
          <meta
            name="description"
            content="Shadow infosystem, best SEO Agency, dedicated to boosting organic traffic & visibility effortlessly. Elevate your online presence and drive success with us"
          />
          <meta
            name="keywords"
            content="SEO services provider company, Best SEO Services Provider, Best SEO Services Company, Best SEO Services Agency"
          />
          <link
            rel="canonical"
            href="https://www.shadowinfosystem.com/search-engine-optimization"
          />
        </Helmet>
        <Header2 />
        <div className="page-content">
          <SEOBanner />
          <SEOAbout />
          <SEOEnquiry />
          <SEOServices />
          <SEOAssist />
          <SEOOnline />
          <SEOService />
          <SEOWhyChooseUs />
          <SEOBrand />
          <SEOWhyus />
          <Testimonials />
          <SEOFAQ />
        </div>

        <Footer2 />
      </>
    );
  }
}

export default Service1;
