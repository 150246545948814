import React from 'react';
import { NavLink } from 'react-router-dom';
import ReactPlayer from 'react-player';

var bgimg1 = require('./../../images/testimonials/Sana-Singh-Review-1-1-140x140@2x.jpg');

class About3 extends React.Component {
    render() {
        return (
            <>
                <div className={`${this.props.bgcolor} section-full mobile-page-padding`}>
                    <div className="container">
                        <div className="section-content">
                        <div className="qpqq">
                        <hr/>
                            <div className="row">
                                
                                <div className="col-xl-8 col-lg-8 col-md-12">
                                    <div className="m-b30 bg-white">
                                        <p className="m-t0 m-b20 sx-tilte"><strong>Sana Singh </strong>United States </p>
                                        <p>Thank you Team Shadow for the excellent work you have performed and continue to do so. Shadow has identified hundreds of issues, many of which are considered priorities, which have improved our platform as well as the user experience. Although we did not think at the outset that it would be such a long project, the continuous discovery of bugs has proven the project’s worthiness. Good environment to work having a positive growth.</p>
                                        
                                        <div className="text-left">
                                            <NavLink to="#" className="site-button btn-half"><span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 256 180"><path fill="red" d="M250.346 28.075A32.18 32.18 0 0 0 227.69 5.418C207.824 0 127.87 0 127.87 0S47.912.164 28.046 5.582A32.18 32.18 0 0 0 5.39 28.24c-6.009 35.298-8.34 89.084.165 122.97a32.18 32.18 0 0 0 22.656 22.657c19.866 5.418 99.822 5.418 99.822 5.418s79.955 0 99.82-5.418a32.18 32.18 0 0 0 22.657-22.657c6.338-35.348 8.291-89.1-.164-123.134Z"/><path fill="#FFF" d="m102.421 128.06l66.328-38.418l-66.328-38.418z"/></svg> Watch Video</span></NavLink>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-12">
                                    <div className="mnbvcx">
                                    <img src={require('./../../images/testimonials/Sana-Singh-Review-1-1-140x140@2x.jpg')} alt="" />
                                    </div>
                            </div>
                                </div>
                                <hr/>
                        </div>
                    </div>
                </div>
                </div>
                
            </>
        );
    }
};

export default About3;