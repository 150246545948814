import React from "react";
import Icon1 from "./../../../images/partner/DV6.webp";
import Icon2 from "./../../../images/partner/DV5.webp";
import Icon3 from "./../../../images/partner/DV4.webp";
import Icon4 from "./../../../images/partner/DV3.webp";
import Icon5 from "./../../../images/partner/DV2.webp";
import Icon6 from "./../../../images/partner/DV1.webp";

function PartnerStep() {
  return (
    <>
      <section id="timeline">
        <div className="sx-separator-outer separator-left">
          <div className="ertyui text-center">
            <h2 className="ert678 heading">Our Partnership Process</h2>
          </div>
        </div>
        <div class="demo-card-wrapper">
          <div class="demo-card redBox">
            <div class="head">
              <div class="number-box">
                <span>Step 1</span>
              </div>
            </div>
            <div class="body">
              <p>
                Register your interest by{" "}
                <a href="/contact-us">
                  <b>filling out our form.</b>
                </a>
              </p>
              <img src={Icon1} alt="Shadow " />
            </div>
          </div>

          <div class="demo-card greenBox">
            <div class="head">
              <div class="number-box">
                <span>Step 2</span>
              </div>
            </div>
            <div class="body">
              <p>Arrange a meeting with our partnership consultant.</p>
              <img src={Icon2} alt="Shadow " />
            </div>
          </div>

          <div class="demo-card blueBox">
            <div class="head">
              <div class="number-box">
                <span>Step 3</span>
              </div>
            </div>
            <div class="body">
              <p> Sign an NDA to ensure confidentiality.</p>
              <img src={Icon3} alt="Shadow " />
            </div>
          </div>

          <div class="demo-card yellowBox">
            <div class="head">
              <div class="number-box">
                <span>Step 4</span>
              </div>
            </div>
            <div class="body">
              <p>Execute the partnership agreement form.</p>
              <img src={Icon4} alt="Shadow " />
            </div>
          </div>

          <div class="demo-card purpleBox">
            <div class="head">
              <div class="number-box">
                <span>Step 5</span>
              </div>
            </div>
            <div class="body">
              <p>We engage with your clients and address their concerns.</p>
              <img src={Icon5} alt="Shadow " />
            </div>
          </div>

          <div class="demo-card redBox">
            <div class="head">
              <div class="number-box">
                <span>Step 6</span>
              </div>
            </div>
            <div class="body">
              <p>
                Reap benefits while we deliver results to your clients (T&C
                apply).
              </p>
              <img src={Icon6} alt="Shadow " />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default PartnerStep;
