import React from "react";
import Header2 from "./../Common/Header3";
import Footer from "./../Common/Footer";
import Footer2 from "./../Common/Footer2";
import WebsiteDevelopmentCompanyNoida from "./../Elements/WebsiteDevelopmentCompanyNoida";
import { Helmet } from "react-helmet";

var bnrimg = require("../../images/banner/shadow-banner-19.jpg");

class Service1 extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <title>Best Website Development Company in Noida</title>
          <meta name="description" content="Shadow infosystem, the top website development company in Noida. Elevate your online presence with expert solutions. Contact us for unparalleled excellence" />
          <meta name="keywords" content="Website Development Company in Noida | Top Website Development Agency" />
          <link rel="canonical" href=" https://www.shadowinfosystem.com/website-development-company-noida" />
        </Helmet>
        <Header2 />
        <div className="page-content">
          <WebsiteDevelopmentCompanyNoida />
        </div>

        <Footer2 />
      </>
    );
  }
}
export default Service1;
