import React from "react";
import { NavLink } from "react-router-dom";
import Header2 from "./../Common/Header3";
import Footer2 from "./../Common/Footer2";
import Banner from "./../Elements/CaseStudy/Banner";
import About from "./../Elements/CaseStudy/About";
import ImageGallery from "react-image-gallery";
import { Helmet } from "react-helmet";
import "../../../node_modules/react-image-gallery/styles/css/image-gallery.css";

const realstate = [
  {
    image: require("./../../images/ozone_ayurvedics/desktop_banner.webp"),
    title: "Ozone Ayurvedics",
    category: "Beauty Products",
    URL: "/case-study-ozoneayurvedics",
  },
  {
    image: require("./../../images/santosh_academia/desktop_banner.webp"),
    title: "Santosh Academia",
    category: "Education Institute",
    URL: "/case-study-santoshacademia",
  },
  {
    image: require("./../../images/nail_rituals/desktop_banner.webp"),
    title: "Nail Rituals",
    category: "Nail Salon",
    URL: "/case-study-nailrituals",
  },
  {
    image: require("./../../images/olivastu/desktop_banner.webp"),
    title: "Olivastu Ltd.",
    category: "Smoking Accessories",
    URL: "/case-study-olivastu",
  },
  {
    image: require("./../../images/massist/desktop_banner.webp"),
    title: "Massist CRM",
    category: "CRM Software Solutions",
    URL: "/case-study-massistcrm",
  },
  {
    image: require("./../../images/new/vital_desktop_banner.webp"),
    title: "Vital UK",
    category: "Healthcare",
    URL: "/case-study-vital-uk",
  },
  {
    image: require("./../../images/ssr_studio/desktop_banner.webp"),
    title: "SSR Studio USA",
    category: "Hair Stylist & Makeup Artist",
    URL: "/case-study-ssrstudio",
  },
  {
    image: require("./../../images/stteresa/stteresa-pc.webp"),
    title: "St. Teresa School",
    category: "School",
    URL: "/case-study-stteresa",
  },
  {
    image: require("./../../images/allways/desktop_banner.webp"),
    title: "Allways Logistics Group",
    category: "Logistics",
    URL: "/case-study-allwayslogistics",
  },
  {
    image: require("./../../images/ashoka/desktop.webp"),
    title: "Ashoka Institute",
    category: "College",
    URL: "/case-study-ashoka-institute",
  },
  {
    image: require("./../../images/sara/desktop.webp"),
    title: "Sara Consultancy",
    category: "Consultancy",
    URL: "/case-study-sara-consultancy",
  },
  {
    image: require("./../../images/david/desktop_banner.webp"),
    title: "David The Tree Guy",
    category: "Tree Arborist",
    URL: "/case-study-davidthetreeguy",
  },
  {
    image: require("./../../images/roadload/desktop_banner.webp"),
    title: "EcoXpress Roadload",
    category: "Logistics",
    URL: "/case-study-roadload",
  },
  {
    image: require("./../../images/meridianivf/desktop_banner.webp"),
    title: "Meridian IVF",
    category: "Hospital",
    URL: "/case-study-meridianivf",
  },
  {
    image: require("./../../images/tatvabir/desktop_banner.webp"),
    title: "Tatva Bir Resort",
    category: "Hotel & Resort",
    URL: "/case-study-tatvabirresort",
  },
  {
    image: require("./../../images/mydentalkraft/desktop_banner.webp"),
    title: "My Dental Kraft",
    category: "Dental Clinic",
    URL: "/case-study-mydentalkraft",
  },
  {
    image: require("./../../images/shri_mahalaxmi_associates/desktop_banner.webp"),
    title: "Shri Mahalaxmi Associates",
    category: "Real Estate",
    URL: "/case-study-shrimahalaxmiassociates",
  },
  {
    image: require("./../../images/engravers_sign/desktop_banner.webp"),
    title: "Engravers Sign",
    category: "Decorative Interiors",
    URL: "/case-study-engraverssign",
  },
  {
    image: require("./../../images/jindal_tiles/desktop_banner.webp"),
    title: "Jindal Tiles",
    category: "Interiors & Furnitures",
    URL: "/case-study-jindaltiles",
  },
  {
    image: require("./../../images/advanced_amorphous/desktop_banner.webp"),
    title: "Advanced Amorphous",
    category: "Electrical Insulation Materials Provider",
    URL: "/case-study-advanced-amorphous",
  },
  {
    image: require("./../../images/trishul/desktop_banner.webp"),
    title: "Trishul Engineering Co",
    category: "Transformer Manufacturer",
    URL: "/case-study-trishul",
  },
  {
    image: require("./../../images/300_experts/desktop_banner.webp"),
    title: "300 Experts",
    category: "Real Estate ",
    URL: "/case-study-300-experts",
  },
  {
    image: require("./../../images/geetanjalidentals/desktop_banner.webp"),
    title: "Geetanjali 32 Dentals",
    category: "Real Estate ",
    URL: "/case-study-geetanjali-dentals",
  },
];

var bnrimg = require("./../../images/banner/5.jpg");

class ShopDetail extends React.Component {
  componentDidMount() {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement("script");
        script.src = src;
        script.addEventListener("load", function () {
          resolve();
        });
        script.addEventListener("error", function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }

    loadScript("./assets/js/custom.js");
  }
  render() {
    return (
      <>
        <Helmet>
          <title>Explore Success Stories of Shadow infosystem Pvt Ltd</title>
          <meta
            name="description"
            content="Know our transformative case studies showcasing Shadow infosystem’s innovative solutions, empowering businesses to thrive in the digital landscape."
          />
          <meta
            name="keywords"
            content="Website Development Company in Noida"
          />
          <link
            rel="canonical"
            href="https://www.shadowinfosystem.com/case-study"
          />
        </Helmet>
        <Header2 />
        <div className="page-content">
          <Banner />

          {/* SHOP DETAIL SECTION START */}
          <div className="section-full p-t80 p-b50 mobile-page-padding back-ground-color">
            <div className=" woo-entry">
              {/* TABS CONTENT START */}
              <div className="product-full-detail m-b30">
                <div className="sx-tabs tabs-default">
                  {/*<ul className="nav nav-tabs">
                                <li><a data-toggle="tab" href="#web-design-19" className="active">Real Estate</a></li>
                                <li><a data-toggle="tab" href="#developement-19">Medical</a></li>
                                <li><a data-toggle="tab" href="#developement-20">Education</a></li>
                                
                                <li><a data-toggle="tab" href="#developement-22">e-Commerce</a></li>
                                
                                <li><a data-toggle="tab" href="#developement-24">Logistics</a></li>
                                <li><a data-toggle="tab" href="#developement-25">Industry</a></li>
                            </ul>*/}
                  <div className="tab-content">
                    <div id="web-design-19" className="tab-pane active">
                      <div className="container">
                        <h1 className="ert678">
                          Success Hub: Customer Stories, Successful Case Study
                        </h1>
                        <h6 className="mb__30">
                          There are so many things to think about, like whether
                          the agency can actually deliver on their promises or
                          if they’ll drain your cash every month with no return.
                        </h6>
                        {/* GOOGLE MAP & CONTACT FORM */}
                        <div className="section-content">
                          {/* CONTACT FORM*/}
                          <div className="row">
                            {/* ABOUT COMPANY */} {/* USEFUL LINKS */}
                            {realstate.map((item, index) => (
                              <div className="col-lg-6 col-md-12 col-sm-12">
                                <div className="case-sudia">
                                  <NavLink to={item.URL} target="_blank">
                                    <img src={item.image} alt="" />
                                    <div className="overlay"></div>
                                    <div className="sdsf687878">
                                      <h4 className="m-t0">{item.title}</h4>
                                      <p>{item.category}</p>
                                      <div className="scvnvnlkj">
                                        <NavLink
                                          to={item.URL}
                                          className="hbutton123"
                                          target="_blank"
                                        >
                                          {" "}
                                          View Details{" "}
                                        </NavLink>
                                      </div>
                                    </div>
                                  </NavLink>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* TABS CONTENT START */} {/* TITLE START */}
            </div>
          </div>
          {/* SHOP DETAIL SECTION END */}
        </div>
        <Footer2 />
      </>
    );
  }
}

export default ShopDetail;
