import React from 'react';
import { NavLink } from 'react-router-dom';
import ClientsLogo5 from './../../Elements/ClientsLogo5';

var bgimg1 = require('./../../../images/background/bg-5.png');
var bgimg2 = require('./../../../images/background/14803753_Vector_2677-1-scaled.jpg');
var bgimg3 = require('./../../../images/background/cross-line2.png');

class WhatWeDo1 extends React.Component {
    render() {
        return (
            <>
                <div className="section-full  mobile-page-padding bg-white1  p-t80 p-b30 bg-repeat overflow-hide back-ground-color">
                {/*<span class="absolute bottom-0 right-0 block w-64 h-64 mb-32 mr-4 transition duration-500 origin-bottom-left transform rotate-45 translate-x-24 bg-pink-500 rounded-full opacity-30 group-hover:rotate-90 ease"></span>*/}
                    <div className="container right-half-bg-image-outer">
                         
                        <div className="bg-parallax bg-fixed bg-top-right" data-stellar-background-ratio={0} style={{ backgroundImage: 'url(' + bgimg2 + ')' }} />
                        
                       
                        <div className="section-content">
                            <div className="row number-block-one-outer justify-content-center">

                                
                                <div className="col-lg-6 col-md-12 col-sm-12 m-b30">
                                    <div className="number-block-one  animate-in-to-top fgghhjkhjjg">
                                        <img src={require('./../../../images/background/Asset 2.png')} alt="" />
                                        
                                    </div>
                                    
                                   
                                    
                                  
                                </div>
                                <div className="col-lg-6 col-md-12 col-sm-12 m-b30">
                                    <div className="">
                                <div className="sx-separator-outer">
                                    <div className="">
                                        <h2 className="wfgrbwe ert678">Our SMM Work Includes</h2>
                                        <p>At Shadow infosystem, actions speak louder than words. We are committed to delivering on our promises and excelling in our areas of expertise. As a premier social media marketing agency, we offer budget-friendly packages that yield favorable outcomes. Regardless of your business size, our skilled team ensures you establish a dynamic and favorable presence on various social media platforms. </p>
                                    </div>
                                    
                                    
                                </div>
                            </div>
                                    
                                   
                                    
                                  
                                </div>

                                

                            </div>

                          

                        </div>

                    </div>
                </div>
            </>
        );
    }
};

export default WhatWeDo1;