import React from "react";
import Header2 from "./../Common/Header2";
import Slider from "./../Elements/Home/Slider";
import About from "./../Elements/Home/About";
import AboutNumber from "./../Elements/Home/AboutNumber";
import Services from "./../Elements/Home/Services";
import ProjectDiscuss from "./../Elements/Home/ProjectDiscuss";
import Customers from "./../Elements/Home/Customers";
import Industry from "./../Elements/Home/Industry";
import WebBanner from "./../Elements/Home/WebBanner";

import Testimonials from "./../Elements/Testimonials";
import CommenEnquiry from "./../Elements/CommenEnquiry";
import Footer2 from "./../Common/Footer2";
import Footer from "./../Common/Footer";
import { Helmet } from "react-helmet";

class Home5 extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <title>Best IT Company in Noida | Shadow infosystem</title>
          <meta
            name="description"
            content="Boost your business with Shadow infosystem – The best IT company in Noida. Delivering innovative solutions, cutting-edge technology, and unmatched expertise"
          />
          <meta name="keywords" content="Best IT Company in Noida" />
          <link rel="canonical" href="https://www.shadowinfosystem.com/" />
        </Helmet>
        <Header2 />
        <div className="page-content">
          {/*<Slider />*/}
          <WebBanner />
          <About />
          <Services />
          <AboutNumber />
          <ProjectDiscuss />

          <CommenEnquiry />
          <Industry />
          <Customers />
          <Testimonials />
        </div>
        <Footer2 />
      </>
    );
  }
}

export default Home5;
