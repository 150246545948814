import React from "react";
import { NavLink } from "react-router-dom";
import TypeDesign from "./../TypeDesign";

const achievements1 = [
  {
    title: "Healthcare ",
    image: require("./../../../images/client-logo/medical-check.png"),
  },
  {
    title: "Manufacturing",
    image: require("./../../../images/client-logo/factory.png"),
  },
  {
    title: "Retail",
    image: require("./../../../images/client-logo/checklist.png"),
  },
  {
    title: "Government",
    image: require("./../../../images/client-logo/hands.png"),
  },
];
const achievements2 = [
  {
    title: "News ",
    image: require("./../../../images/client-logo/journalism.png"),
  },
  {
    title: "Real Estate",
    image: require("./../../../images/client-logo/house.png"),
  },
  {
    title: "Electronics",
    image: require("./../../../images/client-logo/gadgets.png"),
  },
  {
    title: "Green Energy",
    image: require("./../../../images/client-logo/energy-saving.png"),
  },
];
const achievements3 = [
  {
    title: "Travel",
    image: require("./../../../images/client-logo/airplane.png"),
  },

  {
    title: "Education",
    image: require("./../../../images/client-logo/mortarboard.png"),
  },
  {
    title: "Finance",
    image: require("./../../../images/client-logo/asset-management.png"),
  },
  {
    title: "Automobile",
    image: require("./../../../images/client-logo/electric-car.png"),
  },
];
const achievements4 = [
  {
    title: "Consultancy",
    image: require("./../../../images/client-logo/recruitment-human.png"),
  },

  {
    title: "Entertainment",
    image: require("./../../../images/client-logo/popcorn.png"),
  },
  {
    title: "Logistics",
    image: require("./../../../images/client-logo/distribution.png"),
  },
  {
    title: "Hospitality",
    image: require("./../../../images/client-logo/hospitality.png"),
  },
];
var bgimg1 = require("./../../../images/bacgarref.png");
var bgimg2 = require("./../../../images/111.png");

class Achievements1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = { logo: require("./../../../images/logo-light.png") };
  }

  state = { isSearchActive: false };

  handleSearchToggle = () => {
    this.setState({ isSearchActive: !this.state.isSearchActive });
  };

  componentDidMount() {
    const handleScroll = () => {
      const offset = window.scrollY;

      const stickyheader = document.querySelector(".sticky-header ");

      if (offset >= 100) {
        stickyheader.classList.add("is-fixed");
        stickyheader.classList.add("color-fill");
      } else {
        stickyheader.classList.remove("is-fixed");
        stickyheader.classList.remove("color-fill");
      }
    };

    window.addEventListener("scroll", handleScroll);

    window.updateTopMostParent = (logopath) => {
      this.setState({ logo: logopath });
    };
  }

  render() {
    const isSearchActive = this.state.isSearchActive;
    return (
      <>
        <div
          className="section-full p-t80  bg-no-repeat bg-bottom-center dvfq099-mhh mobile-page-padding fscs1213098"
          style={{ backgroundImage: "url(" + bgimg1 + ")" }}
        >
          <div className="container">
            {/* TITLE START */}

            {/* TITLE END */}

            <div className="section-content ">
              <div className="row">
                <div className="col-md-5">
                  <div className="mnbv34">
                    <div className="sx-separator-outer separator-left cd0987 ">
                      <div className="white-text">
                        <h2 className="ert678">
                          Driving Success in Every Sector We Touch
                        </h2>

                        <p>
                          Transform your business landscape with our integrated
                          IT and digital marketing services. From strategic
                          planning to precise execution, we guarantee success in
                          every sector we engage. Enhance your brand's digital
                          footprint and achieve remarkable growth through our
                          comprehensive expertise.
                        </p>
                      </div>
                      <div className="mb-3">
                        <TypeDesign />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="row">
                    <div className="col-xl-3 col-lg-3 col-md-6 col-6 m-b30">
                      {achievements1.map((item, index) => (
                        <div className="sx-box our-story" key={index}>
                          <div className="tret">
                            <img src={item.image} alt="industry" />
                            <h5>{item.title}</h5>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 col-6 m-b30 asfw6575">
                      {achievements2.map((item, index) => (
                        <div className="sx-box our-story sfwdw" key={index}>
                          <div className="tret">
                            <img src={item.image} alt="industry" />
                            <h5>{item.title}</h5>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 col-6 m-b30">
                      {achievements3.map((item, index) => (
                        <div className="sx-box our-story" key={index}>
                          <div className="tret">
                            <img src={item.image} alt="industry" />
                            <h5>{item.title}</h5>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 col-6 m-b30 asfw6575">
                      {achievements4.map((item, index) => (
                        <div className="sx-box our-story" key={index}>
                          <div className="tret">
                            <img src={item.image} alt="industry" />
                            <h5>{item.title}</h5>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Achievements1;
