import React from "react";
import Navigation from "./Navigation";
import { NavLink } from "react-router-dom";
import Slider2 from "./../Elements/SimilarProjects1";
import ClientsLogo5 from "./../Elements/ClientsLogo5";

var bnr = require("./../../images/background/vector-row-bg-5.jpg");

class Header2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = { logo: require("./../../images/shadow-png-logo2-1.png") };
  }

  state = { isSearchActive: false, isQuoteActive: false };

  handleSearchToggle = () => {
    this.setState({ isSearchActive: !this.state.isSearchActive });
  };

  handleQuoteToggle = () => {
    this.setState({ isQuoteActive: !this.state.isQuoteActive });
  };

  state = { isSearchActive: false };

  handleSearchToggle = () => {
    this.setState({ isSearchActive: !this.state.isSearchActive });
  };

  componentDidMount() {
    const handleScroll = () => {
      const offset = window.scrollY;

      const stickyheader = document.querySelector(".sticky-header ");

      if (offset >= 100) {
        stickyheader.classList.add("is-fixed");
        stickyheader.classList.add("color-fill");
      } else {
        stickyheader.classList.remove("is-fixed");
        stickyheader.classList.remove("color-fill");
      }
    };

    window.addEventListener("scroll", handleScroll);

    window.updateTopMostParent = (logopath) => {
      this.setState({ logo: logopath });
    };

    function privacyAlert388974000000262003() {
      var privacyTool = document.getElementById(
        "privacyTool388974000000262003"
      );
      var privacyErr = document.getElementById("privacyErr388974000000262003");
      if (privacyTool != undefined && !privacyTool.checked) {
        privacyErr.style.visibility = "visible";
        privacyTool.focus();
        return false;
      }
      return true;
    }
    function disableErr388974000000262003() {
      var privacyTool = document.getElementById(
        "privacyTool388974000000262003"
      );
      var privacyErr = document.getElementById("privacyErr388974000000262003");
      if (
        privacyTool != undefined &&
        privacyTool.checked &&
        privacyErr != undefined
      ) {
        privacyErr.style.visibility = "hidden";
      }
    }

    /* Do not remove this code. */
    function reloadImg388974000000262003() {
      var captcha = document.getElementById("imgid388974000000262003");
      if (captcha.src.indexOf("&d") !== -1) {
        captcha.src =
          captcha.src.substring(0, captcha.src.indexOf("&d")) +
          "&d" +
          new Date().getTime();
      } else {
        captcha.src = captcha.src + "&d" + new Date().getTime();
      }
    }
    function validateEmail388974000000262003() {
      var form = document.forms["WebToLeads388974000000262003"];
      var emailFld = form.querySelectorAll("[ftype=email]");
      var i;
      for (i = 0; i < emailFld.length; i++) {
        var emailVal = emailFld[i].value;
        if (emailVal.replace(/^\s+|\s+$/g, "").length != 0) {
          var atpos = emailVal.indexOf("@");
          var dotpos = emailVal.lastIndexOf(".");
          if (
            atpos < 1 ||
            dotpos < atpos + 2 ||
            dotpos + 2 >= emailVal.length
          ) {
            alert("Please enter a valid email address. ");
            emailFld[i].focus();
            return false;
          }
        }
      }
      return true;
    }

    function checkMandatory388974000000262003() {
      var mndFileds = new Array("Last Name", "Email", "Phone", "Description");
      var fldLangVal = new Array(
        "Your\x20Name",
        "Your\x20Email",
        "Your\x20Phone\x20No.",
        "Project\x20Brief...\x2A"
      );
      for (let i = 0; i < mndFileds.length; i++) {
        var fieldObj =
          document.forms["WebToLeads388974000000262003"][mndFileds[i]];
        if (fieldObj) {
          if (fieldObj.value.replace(/^\s+|\s+$/g, "").length == 0) {
            if (fieldObj.type == "file") {
              alert("Please select a file to upload.");
              fieldObj.focus();
              return false;
            }
            alert(fldLangVal[i] + " cannot be empty.");
            fieldObj.focus();
            return false;
          } else if (fieldObj.nodeName == "SELECT") {
            if (fieldObj.options[fieldObj.selectedIndex].value == "-None-") {
              alert(fldLangVal[i] + " cannot be none.");
              fieldObj.focus();
              return false;
            }
          } else if (fieldObj.type == "checkbox") {
            if (fieldObj.checked == false) {
              alert("Please accept  " + fldLangVal[i]);
              fieldObj.focus();
              return false;
            }
          }
          try {
            if (fieldObj.name == "Last Name") {
              let name = fieldObj.value;
            }
          } catch (e) {}
        }
      }
      if (!validateEmail388974000000262003()) {
        return false;
      }

      if (!privacyAlert388974000000262003()) {
        return false;
      }
      document
        .querySelector(".crmWebToEntityForm .formsubmit")
        .setAttribute("disabled", true);
    }

    function tooltipShow388974000000262003(el) {
      var tooltip = el.nextElementSibling;
      var tooltipDisplay = tooltip.style.display;
      if (tooltipDisplay == "none") {
        var allTooltip = document.getElementsByClassName("zcwf_tooltip_over");
        for (let i = 0; i < allTooltip.length; i++) {
          allTooltip[i].style.display = "none";
        }
        tooltip.style.display = "block";
      } else {
        tooltip.style.display = "none";
      }
    }
  }

  render() {
    const isSearchActive = this.state.isSearchActive;
    const isQuoteActive = this.state.isQuoteActive;

    return (
      <>
        <header className="site-header nav-wide nav-transparent mobile-sider-drawer-menu">
          <div className="top-bar sx-bg-secondry">
            <div className="container"></div>
          </div>
          <div className="sticky-header main-bar-wraper navbar-expand-lg">
            <div className="main-bar">
              <div className="container clearfix">
                <div className="logo-header">
                  <div className="logo-header-inner logo-header-one">
                    <NavLink to={"/"}>
                      <img src={this.state.logo} alt="Shadow infosystem" />
                    </NavLink>
                  </div>
                </div>
                <div className="logo-header ertyuiopoiuy">
                  <div className="logo-header-inner logo-header-one">
                    <div className="brand-logo hide-show poiuyre ">
                      <p>
                        <strong>
                          Serving the Nation
                          <br /> since 2008
                        </strong>
                        <br />
                        3000+ Satisfied Customers{" "}
                      </p>
                      <div className="brands-slider">
                        <figure>
                          <img
                            src={require("./../../images/logo/ucwl.png")}
                            alt="shadow_client"
                          />
                        </figure>

                        <figure>
                          <img
                            src={require("./../../images/logo/vitaluk.png")}
                            alt="shadow_client"
                          />
                        </figure>
                        <figure>
                          <img
                            src={require("./../../images/logo/seashorepipe.png")}
                            alt="shadow_client"
                          />
                        </figure>
                        <figure>
                          <img
                            src={require("./../../images/logo/bcic.png")}
                            alt="shadow_client"
                          />
                        </figure>
                        <figure>
                          <img
                            src={require("./../../images/logo/india-army.png")}
                            alt="shadow_client"
                          />
                        </figure>
                        <figure>
                          <img
                            src={require("./../../images/logo/imr.png")}
                            alt="shadow_client"
                          />
                        </figure>
                        <figure>
                          <img
                            src={require("./../../images/logo/seashorepipe.png")}
                            alt="shadow_client"
                          />
                        </figure>
                        <figure>
                          <img
                            src={require("./../../images/logo/cenjows.png")}
                            alt="shadow_client"
                          />
                        </figure>
                        <figure>
                          <img
                            src={require("./../../images/logo/sprite-India.jpg")}
                            alt="shadow_client"
                          />
                        </figure>
                        <figure>
                          <img
                            src={require("./../../images/logo/st teresa logo.png")}
                            alt="shadow_client"
                          />
                        </figure>
                      </div>
                    </div>
                  </div>
                </div>

                {/* NAV Toggle Button */}
                <button
                  id="mobile-side-drawer"
                  data-target=".header-nav"
                  data-toggle="collapse"
                  type="button"
                  className="navbar-toggler collapsed"
                >
                  <span className="sr-only">Toggle navigation</span>
                  <span className="icon-bar icon-bar-first" />
                  <span className="icon-bar icon-bar-two" />
                  <span className="icon-bar icon-bar-three" />{" "}
                </button>
                {/* EXTRA NAV */}

                <div className="extra-nav erer54566">
                  {/* <div className="header-section-12">
                    <NavLink
                      to={"#"}
                      className="hs12-button"
                      onClick={this.handleQuoteToggle}
                    >
                       Start a Project
                    </NavLink>
                  </div> */}
                  <button class="darksoul-hover-fill-button2" type="button">
                    <div class="color-fill-2"></div>
                    <NavLink
                      to={"#"}
                      className=""
                      onClick={this.handleQuoteToggle}
                    >
                      Start a Project
                    </NavLink>
                  </button>
                </div>

                {/* EXTRA Nav */}

                {/* MAIN NAVIGATION */}
                <div className="header-nav nav-dark navbar-collapse collapse justify-content-center collapse">
                  {/*<Slider2 />*/}

                  <Navigation />
                </div>
                {/* SITE SEARCH */}
                <div
                  className="contact-slide-hide"
                  style={{
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    right: isQuoteActive ? "0px" : "100%",
                  }}
                >
                  <div className="cjcncc935837">
                    <div className="contact-nav">
                      <div className="contact-nav-form">
                        <div className="row">
                          <div className="col-xl-6 col-lg-6 col-md-12 mvgee">
                            <div className="5uigfh ssvdvnlpo">
                              <h4 className="ert678 fef098765">
                                Business Questions? Meet Our Expert
                              </h4>
                              <p className="cnjc0887">
                                Be it a service you need, a service you seek, or
                                if you like what you have seen so far; do not
                                hesitate to reach out. We're excited to start a
                                conversation.
                              </p>
                              <div className="sx-icon-box-wraper left p-b30">
                                <div className="icon-xs inline-icon m-b20 scale-in-center">
                                  <img
                                    src={require("./../../images/flag-50.png")}
                                    className="county-logo"
                                    alt="Inteshape"
                                  />
                                </div>
                                <div className="icon-content">
                                  <h6 className="m-t0">India </h6>
                                  <p>
                                    <a href="tel:+91-8285560008">
                                      +91-8285560008{" "}
                                    </a>
                                  </p>

                                  <p>
                                    <a href="tel:+91-8800384880">
                                      +91-8800384880
                                    </a>
                                  </p>
                                  <p>
                                    <a href="tel:+91-120 426 8670">
                                      +91-120-4268670{" "}
                                    </a>
                                  </p>
                                </div>
                              </div>
                              {/*<div className="sx-icon-box-wraper left p-b30">
                                                            <div className="icon-xs inline-icon m-b20 scale-in-center">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
                                                                    <path
                                                                        fill="white"
                                                                        d="M12.001 2c5.523 0 10 4.477 10 10s-4.477 10-10 10a9.954 9.954 0 0 1-5.03-1.355L2.005 22l1.352-4.968A9.953 9.953 0 0 1 2.001 12c0-5.523 4.477-10 10-10ZM8.593 7.3l-.2.008a.961.961 0 0 0-.372.1a1.293 1.293 0 0 0-.294.228c-.12.113-.188.211-.261.306A2.73 2.73 0 0 0 6.9 9.62c.002.49.13.967.33 1.413c.409.902 1.082 1.857 1.97 2.742c.214.213.424.427.65.626a9.448 9.448 0 0 0 3.84 2.046l.568.087c.185.01.37-.004.556-.013a1.99 1.99 0 0 0 .833-.231a4.83 4.83 0 0 0 .383-.22s.043-.028.125-.09c.135-.1.218-.171.33-.288c.083-.086.155-.187.21-.302c.078-.163.156-.474.188-.733c.024-.198.017-.306.014-.373c-.004-.107-.093-.218-.19-.265l-.582-.261s-.87-.379-1.402-.621a.497.497 0 0 0-.176-.041a.482.482 0 0 0-.378.127c-.005-.002-.072.055-.795.931a.35.35 0 0 1-.368.13a1.43 1.43 0 0 1-.191-.066c-.124-.052-.167-.072-.252-.108a6.025 6.025 0 0 1-1.575-1.003c-.126-.11-.243-.23-.363-.346a6.297 6.297 0 0 1-1.02-1.268l-.059-.095a.923.923 0 0 1-.102-.205c-.038-.147.061-.265.061-.265s.243-.266.356-.41c.11-.14.203-.276.263-.373c.118-.19.155-.385.093-.536c-.28-.684-.57-1.365-.868-2.041c-.059-.134-.234-.23-.393-.249c-.054-.006-.108-.012-.162-.016a3.385 3.385 0 0 0-.403.004l.201-.008Z"
                                                                    />
                                                                </svg>
                                                            </div>
                                                            <div className="icon-content">
                                                                <h6 className="m-t0">WhatsApp: </h6>
                                                                <p><a href="https://api.whatsapp.com/send?phone=+918800384880&text=Hello Shadow" target="_blank">+91-8800384880 </a></p>
                                                            </div>
                                                        </div>*/}

                              <div className="sx-icon-box-wraper left p-b30">
                                <div className="icon-xs inline-icon m-b20 scale-in-center">
                                  <img
                                    src={require("./../../images/usa-50.png")}
                                    className="county-logo"
                                    alt="Inteshape"
                                  />
                                </div>
                                <div className="icon-content">
                                  <h6 className="m-t0">USA </h6>
                                  <p>
                                    <a
                                      href="tel:(+1)-720-384-8706"
                                      target="_blank"
                                    >
                                      {" "}
                                      (+1)-720-384-8706
                                    </a>
                                  </p>
                                </div>
                              </div>
                              <div className="sx-icon-box-wraper left p-b30">
                                <div className="icon-xs inline-icon m-b20 scale-in-center">
                                  <img
                                    src={require("./../../images/canada-1.png")}
                                    className="county-logo"
                                    alt="Inteshape"
                                  />
                                </div>
                                <div className="icon-content">
                                  <h6 className="m-t0">Canada </h6>
                                  <p>
                                    <a href="tel:+17802552727">
                                      {" "}
                                      (+1)-780-255-2727
                                    </a>
                                  </p>
                                </div>
                              </div>
                              <div className="sx-icon-box-wraper left p-b30">
                                <div className="icon-xs inline-icon m-b20 scale-in-center">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="25"
                                    height="25"
                                    viewBox="0 0 512 512"
                                  >
                                    <path
                                      fill="white"
                                      d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4l217.6 163.2c11.4 8.5 27 8.5 38.4 0l217.6-163.2c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176v208c0 35.3 28.7 64 64 64h384c35.3 0 64-28.7 64-64V176L294.4 339.2a63.9 63.9 0 0 1-76.8 0L0 176z"
                                    />
                                  </svg>
                                </div>
                                <div className="icon-content">
                                  <h6 className="m-t0">Mail: </h6>
                                  <p>
                                    <a href="mailto:info@shadowinfosystem.com">
                                      {" "}
                                      info@shadowinfosystem.com
                                    </a>
                                  </p>
                                  <p>
                                    <a href="mailto:sales@shadowinfosystem.com">
                                      {" "}
                                      sales@shadowinfosystem.com
                                    </a>
                                  </p>
                                </div>
                              </div>
                              <div className="full-social-bg">
                                <h6 className="m-t0">Follow US: </h6>
                                <ul className="ertyupoi">
                                  <li>
                                    <a
                                      href="https://www.facebook.com/shadowinfosystemlimited"
                                      className="facebook"
                                      target="_blank"
                                    >
                                      <i className="fa fa-facebook" />
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="https://www.instagram.com/shadowinfosystem/"
                                      className="instagram"
                                      target="_blank"
                                    >
                                      <i className="fa fa-instagram" />
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="https://www.linkedin.com/company/shadow-infosystem/"
                                      className="tumblr"
                                      target="_blank"
                                    >
                                      <i className="fa fa-linkedin" />
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="https://twitter.com/i/flow/login?redirect_after_login=%2Fshadowinfosyste"
                                      className="twitter"
                                      target="_blank"
                                    >
                                      <img
                                        src={require("./../../images/twitter (1).png")}
                                        className="county-logo1"
                                        alt="Shadow infosystem"
                                      />
                                    </a>
                                  </li>

                                  <li>
                                    <a
                                      href="https://www.youtube.com/@Shadowinfosystem"
                                      target="_blank"
                                      className="youtube"
                                    >
                                      <i className="fa fa-youtube" />
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-12 ddsd">
                            <div className="popup-dsafs">
                              <div className="contact-nav-field bg-white">
                                <NavLink
                                  to={"#"}
                                  className="contact_close"
                                  onClick={this.handleQuoteToggle}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="30"
                                    height="30"
                                    viewBox="0 0 16 16"
                                  >
                                    <path
                                      fill="currentColor"
                                      d="M8 1c3.9 0 7 3.1 7 7s-3.1 7-7 7s-7-3.1-7-7s3.1-7 7-7zm0-1C3.6 0 0 3.6 0 8s3.6 8 8 8s8-3.6 8-8s-3.6-8-8-8z"
                                    />
                                    <path
                                      fill="currentColor"
                                      d="M12.2 10.8L9.4 8l2.8-2.8l-1.4-1.4L8 6.6L5.2 3.8L3.8 5.2L6.6 8l-2.8 2.8l1.4 1.4L8 9.4l2.8 2.8z"
                                    />
                                  </svg>
                                </NavLink>

                                <div className="wevfw47464">
                                  <div
                                    id="crmWebToEntityForm"
                                    className="zcwf_lblTopBottom crmWebToEntityForm dsdwd123121"
                                  >
                                    <form
                                      id="webform"
                                      action="https://crm.zoho.in/crm/WebToLeadForm"
                                      name="WebToLeads388974000000262003"
                                      method="POST"
                                      onSubmit='javascript:document.charset="UTF-8"; return checkMandatory388974000000262003()'
                                      accept-charset="UTF-8"
                                    >
                                      <input
                                        type="text"
                                        className="fda1231"
                                        name="xnQsjsdp"
                                        value="a7d5b3c93d7e1e6a9300406730a7c2e0c67e77a179f2b8d9199598b8690efb95"
                                      />
                                      <input
                                        type="hidden"
                                        name="zc_gad"
                                        id="zc_gad"
                                        value=""
                                      />
                                      <input
                                        type="text"
                                        className="fda1231"
                                        name="xmIwtLD"
                                        value="9f5db9b21a0dc11d8008d1295ced785b8f75126249c5b977cbe98a71a8f2ed06a0f2ba6f1d27e2922c0ac3b87b14582e"
                                      />
                                      <input
                                        type="text"
                                        className="fda1231"
                                        name="actionType"
                                        value="TGVhZHM="
                                      />
                                      <input
                                        type="text"
                                        className="fda1231"
                                        name="returnURL"
                                        value="https&#x3a;&#x2f;&#x2f;www.shadowinfosystem.com&#x2f;thank-you&#x2f;"
                                      />

                                      <div class="zcwf_title zogifsq">
                                        <h4 class="ert678">
                                          Elevate Your Business Journey
                                        </h4>
                                      </div>
                                      <p className="cnjc0887">
                                        If you have a new project that you wish
                                        to discuss or would like to visit our
                                        office, please provide us with your
                                        details.
                                      </p>

                                      <div className="zcwf_row">
                                        <div className="zcwf_col_fld">
                                          <input
                                            type="text"
                                            id="Last_Name"
                                            name="Last Name"
                                            maxlength="80"
                                            placeholder="Full Name"
                                          />
                                        </div>
                                      </div>
                                      <div className="zcwf_row">
                                        <div className="zcwf_col_fld">
                                          <input
                                            type="text"
                                            id="Company"
                                            name="Company"
                                            placeholder="Company Organization"
                                            maxlength="200"
                                          />
                                        </div>
                                      </div>
                                      <div className="zcwf_row">
                                        <div className="zcwf_col_fld">
                                          <input
                                            type="text"
                                            ftype="email"
                                            autocomplete="false"
                                            id="Email"
                                            name="Email"
                                            maxlength="100"
                                            placeholder="Email"
                                          />
                                        </div>
                                      </div>
                                      <div className="zcwf_row">
                                        <div className="zcwf_col_fld">
                                          <input
                                            type="text"
                                            id="Phone"
                                            name="Phone"
                                            maxlength="30"
                                            placeholder="Mobile"
                                          />
                                        </div>
                                      </div>
                                      <div className="zcwf_row">
                                        <div className="zcwf_col_fld">
                                          <textarea
                                            id="Description"
                                            name="Description"
                                            className="mncs098986"
                                            placeholder="Please Describe Your Requirement"
                                          ></textarea>
                                          <div className="zcwf_col_help"></div>
                                        </div>
                                      </div>
                                      <div className="zcwf_row">
                                        <div className="zcwf_col_fld">
                                          <input
                                            type="text"
                                            maxlength="10"
                                            name="enterdigest"
                                            placeholder="Enter the Captcha"
                                          />
                                        </div>
                                      </div>

                                      <div className="zcwf_row">
                                        <div className="zcwf_col_fld">
                                          <img
                                            className="border"
                                            id="imgid388974000000262003"
                                            src="https://crm.zoho.in/crm/CaptchaServlet?formId=9f5db9b21a0dc11d8008d1295ced785b8f75126249c5b977cbe98a71a8f2ed06a0f2ba6f1d27e2922c0ac3b87b14582e&grpid=a7d5b3c93d7e1e6a9300406730a7c2e0c67e77a179f2b8d9199598b8690efb95"
                                          />
                                          <NavLink
                                            className="button-enq default"
                                            href="javascript:;"
                                            onclick="reloadImg388974000000262003();"
                                          >
                                            Reload
                                          </NavLink>
                                        </div>
                                        <div className=""></div>
                                      </div>
                                      <div className="zcwf_row">
                                        <div className="zcwf_privacy">
                                          <div className="dIB vat" align="left">
                                            <div className="displayPurpose f13">
                                              <label className="newCustomchkbox-md dIB w100per">
                                                {" "}
                                                <input
                                                  autocomplete="off"
                                                  id="privacyTool388974000000262003"
                                                  type="checkbox"
                                                  checked
                                                  name="privacyTool"
                                                  onclick="disableErr388974000000262003()"
                                                />
                                              </label>
                                            </div>
                                          </div>
                                          <div className="dIB zcwf_privacy_txt ssszoho">
                                            I agree to the{" "}
                                            <a href="privacy-policy">
                                              {" "}
                                              <b>Privacy Policy</b>
                                            </a>{" "}
                                            and{" "}
                                            <a href="terms-and-conditions">
                                              <b>Terms of Service.</b>
                                            </a>
                                          </div>
                                          <div
                                            id="privacyErr388974000000262003"
                                            className="sjnczoho"
                                          >
                                            Please accept this
                                          </div>
                                        </div>
                                      </div>
                                      <div className="zcwf_row">
                                        <div className="zcwf_col_lab"></div>
                                        <div className="zcwf_col_fld">
                                          <input
                                            type="submit"
                                            id="formsubmit"
                                            className="formsubmit zcwf_button sw34rtghbvcxer"
                                            value="Submit"
                                            title="Submit"
                                          />
                                        </div>
                                      </div>
                                    </form>

                                    <iframe
                                      name="captchaFrame"
                                      className="fda1231"
                                    ></iframe>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </>
    );
  }
}

export default Header2;
