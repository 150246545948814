import React from "react";
import { NavLink } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const clientele = [
  {
    id: 1,
    image: require("./../../../images/client/new/vital.jpg"),
    items: ["UK", "SGP", "IND"],
  },
  {
    id: 2,
    image: require("./../../../images/client/new/sara.jpg"),
    items: ["Canada"],
  },
  {
    id: 3,
    image: require("./../../../images/client/new/seashore_pipes.jpg"),
    items: ["Qatar"],
  },
  {
    id: 4,
    image: require("./../../../images/client/new/indian_army.jpg"),
    items: ["Govt. of India"],
  },
  {
    id: 5,
    image: require("./../../../images/client/new/drdo.jpg"),
    items: ["Govt. of India"],
  },
  {
    id: 6,
    image: require("./../../../images/client/new/imr.jpg"),
    items: ["Govt. of India"],
  },
  {
    id: 7,
    image: require("./../../../images/client/new/cenjows.jpg"),
    items: ["Govt. of India"],
  },
  {
    id: 8,
    image: require("./../../../images/client/new/dipm.jpg"),
    items: ["Govt. of India"],
  },
  {
    id: 9,
    image: require("./../../../images/client/new/gctc.jpg"),
    items: ["Govt. of India"],
  },
  {
    id: 10,
    image: require("./../../../images/client/new/ssr.jpg"),
    items: ["USA"],
  },
  {
    id: 11,
    image: require("./../../../images/client/new/dunex.jpg"),
    items: ["IND", "USA", "UK", "MENA"],
  },
  {
    id: 12,
    image: require("./../../../images/client/new/sonali_ahuja.jpg"),
    items: ["USA"],
  },
  {
    id: 13,
    image: require("./../../../images/client/new/santosh.jpg"),
    items: ["IND"],
  },
  {
    id: 14,
    image: require("./../../../images/client/new/ozone.jpg"),
    items: ["IND"],
  },
  {
    id: 15,
    image: require("./../../../images/client/new/gold_gym.jpg"),
    items: ["IND"],
  },
  {
    id: 16,
    image: require("./../../../images/partner/hq-ids.webp"),
    items: ["Govt. of India"],
  },
  {
    id: 17,
    image: require("./../../../images/client/new/afowo.jpg"),
    items: ["Govt. of India"],
  },
  {
    id: 18,
    image: require("./../../../images/client/new/om_my_grill.jpg"),
    items: ["USA"],
  },
  {
    id: 19,
    image: require("./../../../images/client/new/jklaxmi.jpg"),
    items: ["IND"],
  },
  {
    id: 20,
    image: require("./../../../images/client/new/shavs.jpg"),
    items: ["USA"],
  },
  {
    id: 21,
    image: require("./../../../images/client/new/bamboo_garden.jpg"),
    items: ["USA"],
  },
  {
    id: 22,
    image: require("./../../../images/client/new/andosa.jpg"),
    items: ["IND", "NEP"],
  },
  {
    id: 23,
    image: require("./../../../images/client/new/fiwe.jpg"),
    items: ["Sweden"],
  },
  {
    id: 24,
    image: require("./../../../images/client/new/aparna.jpg"),
    items: ["IND", "UAE"],
  },
  {
    id: 25,
    image: require("./../../../images/client/new/bcic.jpg"),
    items: ["IND"],
  },
  {
    id: 26,
    image: require("./../../../images/client/new/4qt.jpg"),
    items: ["IND"],
  },
  {
    id: 27,
    image: require("./../../../images/client/new/resistoflex.jpg"),
    items: ["IND"],
  },
  {
    id: 28,
    image: require("./../../../images/client/new/microtek.jpg"),
    items: ["IND"],
  },
  {
    id: 29,
    image: require("./../../../images/client/new/ucwl.jpg"),
    items: ["IND"],
  },
  {
    id: 30,
    image: require("./../../../images/client/new/nail_rituals.jpg"),
    items: ["IND"],
  },
  {
    id: 31,
    image: require("./../../../images/client/new/david.jpg"),
    items: ["Canada"],
  },
  {
    id: 32,
    image: require("./../../../images/client/new/allways.jpg"),
    items: ["IND", "USA", "CHN"],
  },
  {
    id: 33,
    image: require("./../../../images/client/new/orbit.jpg"),
    items: ["IND", "GER"],
  },
  {
    id: 34,
    image: require("./../../../images/client/new/ashoka.jpg"),
    items: ["IND"],
  },
  {
    id: 35,
    image: require("./../../../images/client/new/meridian_hospital.jpg"),
    items: ["IND"],
  },
  {
    id: 36,
    image: require("./../../../images/client/new/acres_n_inches.jpg"),
    items: ["IND"],
  },
  {
    id: 37,
    image: require("./../../../images/new-client/FRESH.webp"),
    items: ["CA"],
  },
  {
    id: 38,
    image: require("./../../../images/new-client/au.webp"),
    items: ["IND"],
  },
  {
    id: 39,
    image: require("./../../../images/new-client/SYMMETRY.webp"),
    items: ["IND"],
  },
  {
    id: 40,
    image: require("./../../../images/new-client/fortis.webp"),
    items: ["IND"],
  },
  {
    id: 41,
    image: require("./../../../images/new-client/sprite.webp"),
    items: ["IND"],
  },
  {
    id: 42,
    image: require("./../../../images/new-client/MAHARAJA.webp"),
    items: ["CA"],
  },
  {
    id: 43,
    image: require("./../../../images/new-client/GLOW.webp"),
    items: ["NEPAL"],
  },
  {
    id: 44,
    image: require("./../../../images/new-client/GREENVENT.webp"),
    items: ["IND"],
  },
  {
    id: 45,
    image: require("./../../../images/new-client/SHARDA.webp"),
    items: ["IND"],
  },
  {
    id: 46,
    image: require("./../../../images/new-client/MASSIST.webp"),
    items: ["IND"],
  },
  {
    id: 47,
    image: require("./../../../images/new-client/STTERESA.webp"),
    items: ["IND"],
  },
  {
    id: 48,
    image: require("./../../../images/new-client/SG.webp"),
    items: ["IND"],
  },
  {
    id: 49,
    image: require("./../../../images/new-client/KOTHARI.webp"),
    items: ["IND"],
  },
  {
    id: 50,
    image: require("./../../../images/new-client/VEDAM.webp"),
    items: ["IND"],
  },
  {
    id: 51,
    image: require("./../../../images/partner/Nino-nina.webp"),
    items: ["IND"],
  },
  {
    id: 52,
    image: require("./../../../images/new-client/rav.webp"),
    items: ["IND"],
  },
  {
    id: 53,
    image: require("./../../../images/new-client/AGARWAL.webp"),
    items: ["IND"],
  },
  {
    id: 54,
    image: require("./../../../images/new-client/IIT-PATNA.webp"),
    items: ["IND"],
  },
  {
    id: 55,
    image: require("./../../../images/new-client/ISAB.webp"),
    items: ["IND"],
  },
  {
    id: 56,
    image: require("./../../../images/new-client/maha.webp"),
    items: ["महाराष्ट्र शासन"],
  },
  {
    id: 57,
    image: require("./../../../images/new-client/NASCENT.webp"),
    items: ["IND"],
  },
  {
    id: 58,
    image: require("./../../../images/new-client/DEKOBUDDY.webp"),
    items: ["IND"],
  },
  {
    id: 59,
    image: require("./../../../images/new-client/IAIT.webp"),
    items: ["IND"],
  },
  {
    id: 60,
    image: require("./../../../images/new-client/REAPL.webp"),
    items: ["IND"],
  },
  {
    id: 61,
    image: require("./../../../images/new-client/SCM.webp"),
    items: ["IND"],
  },
  {
    id: 62,
    image: require("./../../../images/new-client/ROAD LOAD.webp"),
    items: ["IND"],
  },
  {
    id: 63,
    image: require("./../../../images/new-client/utkarsh.webp"),
    items: ["IND"],
  },
  {
    id: 64,
    image: require("./../../../images/new-client/aep.webp"),
    items: ["IND"],
  },
  {
    id: 65,
    image: require("./../../../images/new-client/ADVANCE.webp"),
    items: ["IND"],
  },
  {
    id: 66,
    image: require("./../../../images/partner/Trishul.webp"),
    items: ["IND"],
  },
  {
    id: 67,
    image: require("./../../../images/new-client/SPOTLITE.webp"),
    items: ["IND"],
  },
  {
    id: 68,
    image: require("./../../../images/new-client/tnt.webp"),
    items: ["IND"],
  },
  {
    id: 69,
    image: require("./../../../images/new-client/VARANASI.webp"),
    items: ["IND"],
  },
  {
    id: 70,
    image: require("./../../../images/new-client/me.webp"),
    items: ["USA"],
  },
  {
    id: 71,
    image: require("./../../../images/new-client/300.webp"),
    items: ["IND"],
  },
  {
    id: 72,
    image: require("./../../../images/new-client/au.webp"),
    items: ["IND"],
  },
  {
    id: 73,
    image: require("./../../../images/new-client/LOGISOFT.webp"),
    items: ["IND"],
  },
  {
    id: 74,
    image: require("./../../../images/new-client/NVOCC.webp"),
    items: ["IND", "CH"],
  },
  {
    id: 75,
    image: require("./../../../images/new-client/ENGRAVER.webp"),
    items: ["IND"],
  },
  {
    id: 76,
    image: require("./../../../images/new-client/ZYNATY.webp"),
    items: ["IND", "UKE"],
  },
  {
    id: 77,
    image: require("./../../../images/new-client/JINDAL.webp"),
    items: ["IND"],
  },
  {
    id: 78,
    image: require("./../../../images/new-client/USCNIMA.webp"),
    items: ["IND"],
  },
  {
    id: 79,
    image: require("./../../../images/new-client/CINNOMAN.webp"),
    items: ["IND"],
  },
  {
    id: 80,
    image: require("./../../../images/new-client/quantum.webp"),
    items: ["IND", "UK"],
  },
  {
    id: 81,
    image: require("./../../../images/new-client/SONU.webp"),
    items: ["IND"],
  },
  {
    id: 82,
    image: require("./../../../images/new-client/GEETANJALI.webp"),
    items: ["IND"],
  },
  {
    id: 83,
    image: require("./../../../images/new-client/trendz.webp"),
    items: ["IND"],
  },
  {
    id: 84,
    image: require("./../../../images/new-client/property-hikes.webp"),
    items: ["IND"],
  },
];

class Projects4 extends React.Component {
  componentDidMount() {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement("script");
        script.src = src;
        script.addEventListener("load", function () {
          resolve();
        });
        script.addEventListener("error", function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }

    loadScript("./assets/js/custom.js");
  }
  render() {
    const options = {
      loop: true,
      autoplay: true,
      center: false,
      items: 3,
      margin: 40,
      nav: false,
      dots: false,
      navText: [
        '<i class="fa fa-angle-left"></i>',
        '<i class="fa fa-angle-right"></i>',
      ],
      responsive: {
        0: {
          items: 1,
          margin: 15,
        },
        240: {
          items: 2,
          margin: 15,
        },
        440: {
          items: 3,
          margin: 15,
        },
        600: {
          items: 4,
          margin: 20,
        },
        800: {
          items: 5,
          margin: 20,
        },
        1000: {
          items: 6,
          margin: 20,
        },
        1200: {
          items: 7,
          margin: 20,
        },
      },
    };
    return (
      <>
        <div className="section-full inner-page-padding p-b50 p-t80 ">
          <div className="container-fluid">
            <div className="section-content">
              <div className="container">
                <div className="section-head">
                  <div className="sx-separator-outer separator-center">
                    <div className="ertyui">
                      <h2 className="ert678 mb__15">
                        Loved By 3000+ Customers All Over The World
                      </h2>
                      <h6 className="mb__0">
                        Our greatest asset is the customers and clients
                        Satisfaction!
                      </h6>
                    </div>
                  </div>
                </div>

                <div className="work-carousel-outer">
                  <div className="row">
                    {clientele.map((item) => (
                      <div className="col-lg-2 col-md-3 col-sm-4 col-6 mb__20">
                        <ul key={`item-${item.id}`} className="cl_container">
                          <div className="client_logo_img_box">
                            <div className="client_logo_img">
                              <img src={item.image} alt="client-logo" />
                            </div>
                          </div>

                          <div className="client_logo_title_container">
                            {item.items.map((country, index) => (
                              <li key={`country-${index}`}>{country}</li>
                            ))}
                          </div>
                        </ul>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Projects4;
